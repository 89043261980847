/* You can add global styles to this file, and also import other style files */
@import "~ngx-toastr/toastr.css";
@import "~ngx-org-chart/_theming.scss";

.text-mute {
  color: #0d6efd !important;
}

.btn-danger {
  color: #fff;
  background-color: #dd1226;
  border-color: #dd1226;
  margin-right: 20px !important;
}

.symbol-eye {
    font-size: 14px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 1px;
    right: 10px;
    height: 100%;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.4s;
    color: #828996;
}

.ngx-org-image {
  width: 2em !important;
  height: 2em !important;
  margin-right: .5em !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  padding: .25em !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.avatar-sm {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin-right: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

//Offset Classes
.me-offset-3 {
  margin-left: -3px;
}
