@import "./scss/custom-bootstrap";
@import "../../../node_modules/bootstrap/scss/bootstrap";

@import "./scss/bootstrap/card";
@import "./scss/bootstrap/alert";
@import "./scss/bootstrap/reboot";
@import "./scss/bootstrap/nav";
@import "./scss/bootstrap/pagination";

@import "./scss/core";

@import "./scss/plugins";

@import "./scss/semi-dark";
@import "./scss/dark-theme";
@import "./scss/header-colors";
@import "~@ng-select/ng-select/themes/default.theme.css";

.btn-secondary,
.btn-primary,
.bg-primary,
.bt-secondary {
  color: white;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #ffffff !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  color: #ffffff !important;
}

.no-event {
  pointer-events: none;
  cursor: no-drop;
}

.not-allowed {
  cursor: not-allowed !important;
}

form {
  .form-check {
    position: relative;
    top: 36px;
  }
}

.rcx-table {
  .card-title {
    i {
      position: relative;
      top: 1px;
    }
  }

  table {
    margin-top: 15px;
    margin-bottom: 0;

    thead {
      border: 2px solid #c5c5c526 !important;
      border-left: hidden;
      border-right: hidden;

      tr th {
        padding: 0.6rem 0.5rem;
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: #c5c5c526;
        }

        td {
          vertical-align: middle;
          border-color: #c5c5c526 !important;

          &.actions {
            display: flex;
            gap: 18px;
            justify-content: center;

            .form-switch {
              margin: 0;
              position: relative;
              top: 3px;
              left: 7px;
              cursor: pointer;

              .form-check-input {
                cursor: pointer;

                &:checked {
                  background-color: #e38638 !important;
                  border-color: #e38638 !important;
                }
              }
            }

            i {
              cursor: pointer;
              text-align: center;
              height: 26px;
              width: 26px;
              border-radius: 0.25rem;

              &::before {
                vertical-align: middle;
                font-size: 16px;
              }
            }

            .spinner-border-sm {
              align-self: center;
              width: 1.4rem;
              height: 1.4rem;
              border-width: 0.2em;
            }
          }
        }
      }

      .no-data {
        td {
          padding: 0.6rem;
          text-align: center;
        }
      }
    }
  }
}

.rcx-ng-select {
  padding: 0;
  // border: hidden !important;
  &.ng-select-opened.ng-select-bottom > .ng-select-container,
  &.ng-select-single .ng-select-container {
    border: hidden;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 38px;
    color: #4c5258;

    .ng-value-container .ng-input {
      top: 8px;
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      border: hidden !important;
      min-height: 36px;
      .ng-value-container {
        .ng-placeholder {
          margin-top: 2px;
        }
      }
    }
  }
}

.rcx-btn {
  position: relative !important;
  top: 30px !important;
}
.input-group-text-sm {
  padding: 0 0.5rem;
}
